import {
  Box,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  Typography,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { blackDark, blackLight, UoMBlue } from 'src/app/color';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import { useSnackbar } from 'notistack';
import { Student } from 'src/common/types/student';
import ProfilePopupBox from './ProfilePopupBox';
import { Folder } from '../../common/types/folder_types';
import { backendExceptionToString } from '../../utils/backendExceptionUtils';
import { addStudentToFolder, getFoldersExcludingStudent } from '../../utils/folderApi';
import { getUserID } from '../../utils/localStorage';
import ConfirmDialog from '../Dialog/ConfirmDialog';
import useLoadingWithMinTime from '../../common/functions/useLoadingWithMinTime';

interface Props {
  info: Student;
  studentID: number;
}

function buildSnackBarKey(studentID: number) {
  return `ProfileCardWithMenu_useSnackbar_${studentID}`;
}

const MINIMUM_DISPLAY_TIME: number = 500;
const REQUIRE_FOLDER_FIELDS: Array<keyof Folder> = ['id', 'name'];

function ProfileCardWithMenu({ info, studentID }: Props) {
  // Previous profile box related features.
  const [showProfilePopupBox, setShowProfilePopupBox] = useState<boolean>(false);
  const handleProfilePopBoxOpen = () => setShowProfilePopupBox(true);
  const handleProfilePopBoxClose = () => setShowProfilePopupBox(false);

  // Folder related variables.
  const [folders, setFolders] = useState<Folder[] | null>([]);
  const { isLoading, runWithMinLoadingTime } = useLoadingWithMinTime();

  // Display information to user.
  const { enqueueSnackbar } = useSnackbar();

  const [profileImage, setProfileImage] = React.useState<string>('');

  useEffect(() => {
    const fetchProfileImage = async () => {
      try {
        const response = await fetch(info.imageUrl);
        const base64Data = await response.text(); // Read as text
        setProfileImage(base64Data);
      } catch (error) {
        console.error('Error loading profile image:', error);
        enqueueSnackbar('Failed to load profile image', { variant: 'error' });
      }
    };
    if (info.imageUrl) {
      fetchProfileImage();
    }
  }, [info.imageUrl]);

  const fetchFolders = async () => {
    const userID = getUserID();
    if (!userID) {
      throw new Error('User ID is null. Data Corruption.');
    }

    // Only need id and name.
    return getFoldersExcludingStudent(userID, studentID, { fields: REQUIRE_FOLDER_FIELDS });
  };

  // Menu
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuBtnClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    // https://stackoverflow.com/questions/74940986/why-does-e-currenttarget-object-become-null-after-awaited-promise-function-call
    // Simple put, after fetching data, current target become null.
    // So backup the target.
    const { currentTarget } = e;
    e.stopPropagation();

    try {
      // Fetch folder
      const foldersBackend = await runWithMinLoadingTime(fetchFolders());

      // Set data.
      setFolders(foldersBackend);

      // Show menu.
      setAnchorEl(currentTarget);
    } catch (error: unknown) {
      const errorMsg = backendExceptionToString(error);
      enqueueSnackbar(errorMsg, { variant: 'error', key: buildSnackBarKey(studentID) });
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setFolders(null);
  };

  // Confirm dialog.
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const [selectedFolder, setSelectedFolder] = useState<Folder | null>(null);

  const handleAddToFolderClick = (folder: Folder) => {
    // Set folder.
    setSelectedFolder(folder);

    // Open dialog.
    setShowConfirmDialog(true);

    // Close the menu.
    setAnchorEl(null);
  };

  const handleDialogCancel = () => {
    setShowConfirmDialog(false);
    setSelectedFolder(null);
  };

  const handleDialogClose = () => {
    setShowConfirmDialog(false);
    setSelectedFolder(null);
  };

  const handleConfirmAdd = async () => {
    if (!selectedFolder) {
      console.debug('Selected folder is null');
      return;
    }

    // Close the menu first.
    setAnchorEl(null);

    // Close the dialog
    setShowConfirmDialog(false);

    try {
      enqueueSnackbar('Working...', { variant: 'info' });

      // Add to folder
      await addStudentToFolder(selectedFolder.id, studentID);

      // Display success message
      enqueueSnackbar('Add to folder successful', { variant: 'success' });
    } catch (exception: unknown) {
      const errorMsg = backendExceptionToString(exception);
      enqueueSnackbar(errorMsg, { variant: 'error' });
    }

    // Always set folder back to null.
    setSelectedFolder(null);
  };

  return (
    <Box>
      <ProfilePopupBox open={showProfilePopupBox} handleClose={handleProfilePopBoxClose} id={studentID} info={info} image={profileImage} />

      <Card sx={{ display: 'flex', flexDirection: 'column', cursor: 'pointer' }}>

        {/* The menu button */}
        <IconButton
          title="Add to folder"
          sx={{ position: 'relative', height: '40px', width: '40px', transition: 'opacity 1s ease' }}
          disabled={isLoading}
          onClick={handleMenuBtnClick}
        >
          {
            isLoading
              ? <CircularProgress size="1rem" />
              : <BookmarkAddIcon fontSize="inherit" />
          }
        </IconButton>

        {/* The card content */}
        <Card onClick={handleProfilePopBoxOpen}>
          { !profileImage ? (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Skeleton variant="rectangular" width="80%" height={200} />
            </Box>
          ) : (
            <CardMedia
              component="img"
              src={profileImage}
              loading="lazy"
              sx={{
                p: '0.5rem',
                objectFit: 'contain',
                backgroundColor: 'white',
                height: '200px',
              }}
            />
          )}
          <CardContent sx={{ backgroundColor: 'white', height: '128px', overflowY: 'auto', overflowX: 'hidden' }}>
            <Typography variant="body1" color={UoMBlue}>
              {`${info.firstName} ${info.lastName}`}
            </Typography>
            <Typography variant="h6" color={blackDark}>
              {info.learningAreas}
            </Typography>
            <Typography variant="body2" color={blackLight}>
              {info.available}
            </Typography>
          </CardContent>
        </Card>
      </Card>

      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleMenuClose}
      >
        {/* Title is in the middle */}
        <Box sx={{ display: 'flex', justifyContent: 'left', mb: '0.5rem' }}>
          <Typography
            sx={{
              p: '0 16px',
              fontWeight: 'bold',
            }}
          >
            Add to Folder
          </Typography>
        </Box>

        {/* Divide the menu and title */}
        <Divider />

        {
          folders
          && (
            <MenuList disablePadding>
              {
                folders.length === 0
                  ? (
                    <Box sx={{ display: 'flex', justifyContent: 'left' }}>
                      <Typography sx={{ mt: '0.5rem', p: '0 16px' }}>
                        No folders can be added
                      </Typography>
                    </Box>
                  )
                  : (
                    folders.map((folder) => (
                      <MenuItem
                        key={folder.id}
                        sx={{ maxWidth: '180px' }}
                        onClick={() => handleAddToFolderClick(folder)}
                      >
                        <Typography
                          noWrap
                          sx={{
                            // Refer to https://v5.mui.com/material-ui/react-menu/
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {folder.name}
                        </Typography>
                      </MenuItem>
                    ))
                  )
              }
            </MenuList>
          )
        }
      </Menu>

      {/* Confirmation Dialog */}
      {
        (showConfirmDialog && selectedFolder)
        && (
          <ConfirmDialog
            open={showConfirmDialog}
            titleElem="Confirmation"
            messageElem={(
              <>
                Are you sure you want to add the student to the folder
                {' '}
                <Typography
                  component="span"
                  sx={{
                    display: 'inline-block',
                    maxWidth: '100px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    verticalAlign: 'bottom',
                  }}
                >
                  {selectedFolder.name}
                </Typography>
                ?
              </>
            )}
            onConfirm={handleConfirmAdd}
            onCancel={handleDialogCancel}
            onClose={handleDialogClose}
          />
        )
      }
    </Box>
  );
}

export default ProfileCardWithMenu;
