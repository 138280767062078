import {
  Box,
  Button,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  FormHelperText, Container,
} from '@mui/material';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { StatusCodes } from 'http-status-codes';
import FormControl from '@mui/material/FormControl';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { UoMBlue } from '../../../app/color';
import InputTextField from '../../Form/InputTextField';
import ImageUploadV2 from '../../Register/component/ImageUploadV2';
import WarningMessage from '../../Login/components/WarningMessage/WarningMessage';
import SuccessMessage from '../../Register/SuccessMessage/SuccessMessage';
import SingleSelectField from '../../Form/SingleSelectField';
import MultiSelectField from '../../Form/MultiSelectField';
import { signup, getProfile, editProfile } from '../../../utils/studentApi';
import {
  EMAIL_EXISTS_ERROR,
  INTERNAL_SERVER_ERROR,
  USERNAME_PASSWORD_MISMATCH_ERROR,
} from '../../../common/constants/ErrorMessages';
import { courseMatch, courseProgressMatch, locationsMatch, availableMatch, skillMatch, learningAreaMatch, courseSpecializationMatch } from '../../../common/FieldsMatches/studentProfileFieldsMatch';

const validationSchema = Yup.object({
  firstName: Yup
    .string()
    .required('First Name is required.'),
  lastName: Yup
    .string()
    .required('Last Name is required.'),
  preferredName: Yup
    .string(),
  pronouns: Yup
    .string(),
  course: Yup
    .string().required('Course is required field.'),
  courseSpecialization: Yup
    .string()
    .when('course', ([course], schema) => {
      if (course !== 'GECT' && course !== '') {
        return schema.required('Course Specialization is required field.');
      }
      return schema;
    }),
  courseProgress: Yup
    .string().required('Course Progress is required field.'),
  currentLocation: Yup
    .string().required('Current Location is required field.'),
  workWithChild: Yup
    .string().required('Please check one of the selection.'),
  firstAidCertificate: Yup
    .string().required('Please check one of the selection.'),
  skills: Yup
    .string().required('Please select at least one Skill'),
  location: Yup
    .string().required('Please select at least one Location'),
  learningArea: Yup
    .string().required('Please select at least one Learning Area'),
  available: Yup
    .string().required('Please select at least one Skill'),
  image: Yup
    .string(),
});

async function fetchUserData() {
  const response = getProfile(localStorage.getItem('userId') || '');
  const { data } = await response;
  return data;
}

function StudentEditForm() {
  const [base64Data, setbase64Data] = React.useState('');
  const [initValue, setInitValue] = React.useState({
    firstName: '',
    lastName: '',
    preferredName: '',
    pronouns: '',
    course: '',
    courseSpecialization: '',
    courseProgress: '',
    currentLocation: '',
    otherSkillExperience: '',
    locationOption: '',
    learningAreas: '',
    available: '',
  });
  const navigate = useNavigate();
  const [warning, setWarning] = useState({
    shown: false,
    message: '',
  });
  const [success, setSucces] = useState({
    shown: false,
    message: '',
  });

  const submitForm = useCallback(async (value: any) => {
    try {
      const jsonObj = {
        image: value.image || '',
        firstName: value.firstName,
        lastName: value.lastName,
        preferredName: value.preferredName,
        pronouns: value.pronouns,
        course: value.course,
        courseSpecialization: value.courseSpecialization,
        courseProgression: value.courseProgress,
        currentLocation: value.currentLocation,
        workWithChildren: value.workWithChild === 'Yes',
        firstAidCertificate: value.firstAidCertificate,
        otherSkillExperience: value.skills,
        locationOption: value.location,
        learningAreas: value.learningArea,
        available: value.available,
      };
      // For courses with no specializations, do not send an empty value to the backend
      // Instead send back NA
      if (!value.courseSpecialization && Object.keys(courseSpecializationMatch[value.course]).length === 0) {
        jsonObj.courseSpecialization = 'NA';
      }
      const signupResponse = await editProfile(
        localStorage.getItem('userId') || '',
        jsonObj,
        localStorage.getItem('accessToken') || '',
      );
      if (signupResponse.status === StatusCodes.OK) {
        setWarning({
          shown: false,
          message: '',
        });
        setSucces({
          shown: true,
          message: 'Your profile has been updated.',
        });
        setTimeout(() => {
          navigate('/');
        }, 3000);
      }
    } catch (error: any) {
      if (error.response) {
        if (error.response.status >= StatusCodes.INTERNAL_SERVER_ERROR) {
          setWarning({
            shown: true,
            message: INTERNAL_SERVER_ERROR,
          });
        }
        if (error.response.status === StatusCodes.CONFLICT) {
          setWarning({
            shown: true,
            message: EMAIL_EXISTS_ERROR,
          });
        }
        if (error.response.status === StatusCodes.UNAUTHORIZED) {
          setWarning({
            shown: true,
            message: USERNAME_PASSWORD_MISMATCH_ERROR,
          });
        }
      } else {
        setWarning({
          shown: true,
          message: 'Oops! Something went wrong.',
        });
      }
    }
  }, [navigate]);

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      preferredName: '',
      pronouns: '',
      course: '',
      courseSpecialization: '',
      courseProgress: '',
      currentLocation: '',
      workWithChild: '',
      firstAidCertificate: '',
      skills: '',
      location: '',
      learningArea: '',
      available: '',
      image: '',
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: submitForm,
  });

  useEffect(() => {
    fetchUserData()
      .then((userData) => {
        setInitValue({
          firstName: userData.firstName,
          lastName: userData.lastName,
          preferredName: userData.preferredName || '',
          pronouns: userData.pronouns || '',
          course: userData.course,
          courseSpecialization: userData.courseSpecialization !== 'NA' ? userData.courseSpecialization : '',
          courseProgress: userData.courseProgression,
          currentLocation: userData.currentLocation,
          otherSkillExperience: userData.otherSkillExperience,
          locationOption: userData.locationOption,
          learningAreas: userData.learningAreas,
          available: userData.available,
        });
        formik.setFieldValue('image', userData.image);
        formik.setFieldValue('workWithChild', userData.workWithChildren ? 'Yes' : 'No');
        formik.setFieldValue('firstAidCertificate', userData.firstAidCertificate);
      })
      .catch((error) => console.error(error));
  }, []);

  const callBack = (childdata:string) => {
    setbase64Data(childdata);
    formik.setFieldValue('image', childdata.toString());
  };

  const workWithChildHandle = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('workWithChild', event.target.value);
  };

  const firstAidCertificateHandle = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('firstAidCertificate', event.target.value);
  };

  return (
    <Container sx={{ mt: 15 }}>
      <form noValidate className="form" onSubmit={formik.handleSubmit}>
        <h1 className="title" style={{ color: UoMBlue }}>Profile Edit</h1>

        <InputTextField formik={formik} inputName="firstName" label="First Name" required initValue={initValue.firstName} />
        <InputTextField formik={formik} inputName="lastName" label="Last Name" required initValue={initValue.lastName} />
        <InputTextField formik={formik} inputName="preferredName" label="Preferred Name" initValue={initValue.preferredName} />
        <InputTextField formik={formik} inputName="pronouns" label="Pronouns" initValue={initValue.pronouns} />

        <div>
          <SingleSelectField
            required
            formik={formik}
            inputName="course"
            title="Course Name"
            selection={courseMatch}
            initValue={initValue.course}
            style={{ width: formik.values.course !== 'GECT' && formik.values.course !== '' ? '45%' : '100%' }}
            onSelect={(event, selected) => formik.setFieldValue('courseSpecialization', '')}
          />
          {formik.values.course !== 'GECT' && formik.values.course !== '' ? (
            <SingleSelectField
              required
              formik={formik}
              inputName="courseSpecialization"
              title="Course Specialization"
              initValue={initValue.courseSpecialization}
              style={{ width: '53%', float: 'right' }}
              selection={courseSpecializationMatch[formik.values.course] || {}}
            />
          )
            : null }
        </div>
        <SingleSelectField required formik={formik} inputName="courseProgress" title="Course Progression" selection={courseProgressMatch} initValue={initValue.courseProgress} />
        <SingleSelectField required formik={formik} inputName="currentLocation" title="Current Location" selection={locationsMatch} initValue={initValue.currentLocation} helpLink="https://www.vic.gov.au/regional-model-department-education" />

        <Box mt={3}>
          <FormControl onChange={firstAidCertificateHandle} error={formik.touched.firstAidCertificate && Boolean(formik.errors.firstAidCertificate)} style={{ width: '50%' }}>
            <FormLabel required id=" abuttons-group-label">First Aid Certificate </FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              value={formik.values.firstAidCertificate}
            >
              <FormControlLabel value="FA" control={<Radio />} label="First Aid" />
              <FormControlLabel value="FC" control={<Radio />} label="First Aid & CPR" />
              <FormControlLabel value="NO" control={<Radio />} label="No Certificate" />
            </RadioGroup>
          </FormControl>

          <FormControl onChange={workWithChildHandle} error={formik.touched.workWithChild && Boolean(formik.errors.workWithChild)} style={{ width: '50%' }}>
            <FormLabel required id=" abuttons-group-label">Working With Children Check </FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              value={formik.values.workWithChild}
            >
              <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="No" control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </Box>

        <MultiSelectField allowCustomOptions required formik={formik} inputName="skills" title="Skills" selection={skillMatch} initValue={initValue.otherSkillExperience} />
        <MultiSelectField allowCustomOptions required formik={formik} inputName="location" title="Open To Work Locations" selection={locationsMatch} initValue={initValue.locationOption} helpLink="https://www.vic.gov.au/regional-model-department-education" />
        <MultiSelectField
          allowCustomOptions
          required
          formik={formik}
          inputName="learningArea"
          title="Learning Area"
          selection={learningAreaMatch}
          initValue={initValue.learningAreas}
          helpText="Only select the learning areas you are currently studying"
          maxSelection={4}
        />
        <MultiSelectField
          allowCustomOptions
          required
          formik={formik}
          inputName="available"
          title="Available For"
          selection={availableMatch}
          initValue={initValue.available}
          helpText="Only select Casual Relief Teaching if you have VIT registration"
        />

        <div style={{ width: '100%' }}>
          <h3>Upload Photo</h3>
          <p>
            Hint: Upload a photo to personalise your student profile.
            <Tooltip title="Eg: Passport photo, avatar, picture of your pet">
              <InfoOutlinedIcon fontSize="small" style={{ verticalAlign: 'bottom', marginLeft: '5px' }} />
            </Tooltip>
          </p>
          <FormHelperText>{formik.touched.image && formik.errors.image}</FormHelperText>
          <ImageUploadV2 handelCallback={callBack} style={{ width: '100%' }} />
        </div>

        <div style={{ textAlign: 'center' }}>
          {warning.shown ? <WarningMessage content={warning.message} /> : null}
          {success.shown ? <SuccessMessage content={success.message} /> : null}
          <Button
            className="button"
            type="submit"
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            SAVE
          </Button>
        </div>
      </form>
    </Container>
  );
}

export default StudentEditForm;
