import axios from 'axios';

const baseUrl = process.env.REACT_APP_UNI_RECRUIT_API;

axios.interceptors.request.use(
  async (config) => {
    if (localStorage.getItem('accessToken')) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

axios.interceptors.response.use(
  (res: any) => res,
  (error: any) => {
    const status = error.response ? error.response.status : null;
    if (status === 406) {
      localStorage.clear();
      window.location.replace('/');
    }
    if (status === 403) {
      window.location.replace('/forbidden');
    }
    return Promise.reject(error);
  },
);

export const adminLogin = (email: string, password: string) => axios({
  method: 'post',
  url: `${baseUrl}/admin/login`,
  data: {
    email,
    password,
  },
});

export const forgottenAdmin = (email: string) => axios({
  method: 'post',
  url: `${baseUrl}/admin/forget-password-email?email=${encodeURIComponent(email)}`,
});

export const addExternalStudent = (email: string) => axios({
  method: 'post',
  url: `${baseUrl}/admin/add-external-student?email=${encodeURIComponent(email)}`,
});

export const addExternalPartner = (email: string) => axios({
  method: 'post',
  url: `${baseUrl}/admin/add-external-school?email=${encodeURIComponent(email)}`,
});

export const confirmEmailAdmin = (token: string) => axios.get(`${baseUrl}/admin/confirm-email?token=${token}`);

export const resetPasswordAdmin = (email:string, token: string, jsonObj: any) => axios({
  method: 'put',
  url: `${baseUrl}/admin/password/${encodeURIComponent(email)}/${token}`,
  data: jsonObj,
});

export const getStudentsInfo = () => axios({
  method: 'get',
  url: `${baseUrl}/admin/student`,
});

export const getSchoolsInfo = () => axios({
  method: 'get',
  url: `${baseUrl}/admin/school`,
});

export const deleteIdStudent = (id : number) => axios({
  method: 'DELETE',
  url: `${baseUrl}/admin/students/${id}`,
});

export const blockIdStudent = (id : number) => axios({
  method: 'DELETE',
  url: `${baseUrl}/admin/students/block/${id}`,
});

export const deleteIdSchool = (id : number) => axios({
  method: 'DELETE',
  url: `${baseUrl}/admin/schools/${id}`,
});

export const blockIdSchool = (id : number) => axios({
  method: 'DELETE',
  url: `${baseUrl}/admin/schools/block/${id}`,
});
