import { Box, Button, Card, CircularProgress, FormControlLabel, Grid, Link, List, ListItem, ListItemText, Switch, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import Skeleton from '@mui/material/Skeleton';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { getProfile, toggleDirectContact, toggleHiring } from 'src/utils/schoolsApi';
import { StatusCodes } from 'http-status-codes';
import { UoMBlue, blackLight, blackDark } from '../../../app/color';
import { yearMatch, sectorMatch, locationsMatch } from '../../../common/FieldsMatches/studentProfileFieldsMatch';

interface TextFieldInfo {
  id: string;
  label: string;
  value: string;
}
type JsonObject = {
  [key: string]: string;
};

const fieldName = ['schoolName', 'contactName', 'email', 'webAddress',
  'sector', 'yearLevels', 'location', 'imageUrl'];
const fieldMatch: JsonObject = {
  email: 'Contact Email:',
  sector: 'Sector:',
  webAddress: 'Website Address:',
  yearLevels: 'Year Levels:',
  location: 'Location:',
  schoolName: 'Partner:',
  contactName: 'Contact Name:',
  imageUrl: 'ImageUrl',
};

const matchYear = (year:string) => {
  const yearLevel = year.split('-');
  const newYearLevel = [];
  for (let i = 0; i < yearLevel.length; i += 1) {
    newYearLevel.push(yearMatch[yearLevel[i]]);
  }
  return newYearLevel.toString();
};

async function fetchUserData() {
  const response = getProfile(localStorage.getItem('userId') || '', localStorage.getItem('accessToken') || '');
  const { data } = await response;
  const fields = fieldName.map((name) => ({ id: name, label: fieldMatch[name], value: data[name] }));
  fields[4].value = sectorMatch[fields[4].value];
  const year = matchYear(fields[5].value);
  fields[5].value = year;
  fields[6].value = locationsMatch[fields[6].value] || 'No Location';
  return [fields, data.isHiring, data.allowDirectContact];
}

function ProfilePage() {
  const navigate = useNavigate();
  const [fieldInfo, setInfo] = useState<TextFieldInfo[]>([]);
  const [hiring, setHiring] = useState(false);
  const [directContact, setDirectContact] = useState(false);
  const [dataLoading, setDataLoading] = useState(true);
  const [profileImage, setProfileImage] = React.useState<string>('');
  const { enqueueSnackbar } = useSnackbar();

  async function fetchProfileImage(url: string) {
    try {
      const response = await fetch(url);
      const base64Data = await response.text();
      setProfileImage(base64Data);
    } catch (error) {
      console.error('Error loading profile image:', error);
      enqueueSnackbar('Failed to load profile image', { variant: 'error' });
    }
  }
  useEffect(() => {
    fetchUserData()
      .then((userData) => {
        setInfo(userData[0]);
        setHiring(userData[1]);
        setDirectContact(userData[2]);
        setDataLoading(false);
        const imageUrl = userData[0][7]?.value;
        if (imageUrl) {
          fetchProfileImage(imageUrl);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status >= StatusCodes.UNAUTHORIZED) {
          localStorage.clear();
          navigate('/login');
        }
      });
  }, [navigate]);
  return (
    <Grid
      container
      sx={{
        padding: '16px',
        minHeight: '100vh',
        height: 'auto',
        minWidth: '365px',
        justifyContent: 'center',
      }}
    >
      <Grid
        item
        sm={12}
        md={4}
        lg={4}
        sx={{ height: 'auto' }}
      >
        <Box
          sx={{
            margin: '16px',
            height: '97%',
            maxHeight: '650px',
            width: 'auto',
          }}
        >
          <Card
            sx={{
              padding: '16px',
              height: '97%',
              minWidth: '330px',
            }}
          >
            <Typography
              variant="h1"
              sx={{ fontSize: 26, color: 'primary.main' }}
            >
              Preview
            </Typography>
            { dataLoading ? <CircularProgress sx={{ marginLeft: '45%' }} /> : (
              <>
                <Card sx={{ mt: 2,
                  minWidth: '300px',
                  maxWidth: '300px',
                  margin: '24px auto' }}
                >
                  { !profileImage ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Skeleton variant="rectangular" width="80%" height={200} />
                    </Box>
                  ) : (
                    <CardMedia
                      component="img"
                      src={profileImage}
                      loading="lazy"
                      style={{
                        objectFit: 'contain',
                        height: '300px',
                        backgroundColor: 'white',
                      }}
                    />
                  )}
                  <CardContent sx={{ backgroundColor: 'white', height: '160px', overflowY: 'auto', overflowX: 'hidden' }}>
                    <Typography variant="body1" color={UoMBlue}>
                      {fieldInfo.at(5)?.value}

                    </Typography>
                    <Typography variant="h6" color={blackDark}>
                      {fieldInfo.at(0)?.value}
                    </Typography>
                    <Typography
                      variant="body2"
                      color={blackLight}
                      sx={{
                        overflowWrap: 'break-word',
                        width: '100%',
                      }}
                    >
                      {fieldInfo.at(3)?.value}
                    </Typography>
                  </CardContent>
                </Card>
                <Box sx={{ display: 'flex', height: 'fit-content', marginLeft: '119px' }}>
                  <FormControlLabel
                    sx={{
                      display: 'block',
                      mr: '16px',
                    }}
                    control={(
                      <Switch
                        checked={hiring}
                        onChange={() => {
                          setHiring(!hiring);
                          toggleHiring(localStorage.getItem('userId') || '');
                        }}
                        name="hiring"
                        color="primary"
                      />
                    )}
                    label="MTSI Partner"
                  />
                  <Link href="https://education.unimelb.edu.au/community/mteach-partnership-schoolsandcentres" target="_blank">
                    <HelpOutlineOutlinedIcon fontSize="small" style={{ marginTop: '8px' }} />
                  </Link>
                </Box>
                <Box sx={{ display: 'flex', height: 'fit-content ', marginBottom: '20px', marginLeft: '119px' }}>
                  <FormControlLabel
                    sx={{
                      display: 'block',
                      mr: '16px',
                    }}
                    control={(
                      <Switch
                        checked={directContact}
                        onChange={() => {
                          setDirectContact(!directContact);
                          toggleDirectContact(localStorage.getItem('userId') || '');
                        }}
                        name="directContact"
                        color="primary"
                      />
                    )}
                    label="Allow Direct Contact"
                  />
                </Box>
              </>
            )}
          </Card>
        </Box>
      </Grid>
      <Grid
        item
        sm={12}
        md={8}
        lg={8}
        sx={{ overflowWrap: 'break-word' }}
      >
        <Box
          sx={{
            margin: '16px',
            height: '97%',
            maxHeight: '650px',
            // minWidth: '320px',
          }}
        >
          <Card
            sx={{
              padding: '16px',
              minWidth: '330px',
              height: '97%',
            }}
          >
            <Typography
              variant="h1"
              sx={{ fontSize: 26, color: 'primary.main' }}
            >
              Profile Detail
            </Typography>
            { dataLoading ? <CircularProgress sx={{ marginLeft: '45%' }} /> : (
              <>
                <List sx={{ width: 300 }}>
                  {fieldInfo.slice(0, 7).map((field: TextFieldInfo) => (
                    <ListItem key={field.id} sx={{ pt: '4px', pb: '4px', pl: '0', pr: '0' }}>
                      <ListItemText
                        primary={field.label}
                        secondary={field.value}
                        primaryTypographyProps={{
                          sx: {
                            fontSize: 14,
                          },
                        }}
                      />
                    </ListItem>
                  ))}
                </List>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 5 }}>
                  <Button onClick={() => { navigate('/profile-edit'); }} variant="contained" color="primary" sx={{ mr: '16px', marginBottom: '5px' }}>
                    Edit
                  </Button>
                </Box>
              </>
            )}
          </Card>
        </Box>
      </Grid>
    </Grid>
  );
}

export default ProfilePage;
