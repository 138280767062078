import { Box, Button, Card, CardContent, CardMedia, Link, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import Skeleton from '@mui/material/Skeleton';
import { useSnackbar } from 'notistack';
import { blackDark, blackLight, UoMBlue } from 'src/app/color';
import HiringIcon from '../../icons/MTSI-Partner.png';

interface Props {
  id: number,
  info: Array<string>,
  showHiringBadge: boolean,
  showContactButton: boolean,
  imageUrl: string,
}

function ProfileCard({ id, info, showHiringBadge, showContactButton, imageUrl }: Props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [profileImage, setProfileImage] = React.useState<string>('');

  // Display information to user.
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchProfileImage = async () => {
      try {
        const response = await fetch(imageUrl);
        const base64Data = await response.text(); // Read as text
        setProfileImage(base64Data);
      } catch (error) {
        console.error('Error loading profile image:', error);
        enqueueSnackbar('Failed to load profile image', { variant: 'error' });
      }
    };
    if (imageUrl) {
      fetchProfileImage();
    }
  }, [imageUrl]);

  return (
    <div>
      <Card onClick={handleOpen}>
        { !profileImage ? (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Skeleton variant="rectangular" width="80%" height={200} />
          </Box>
        ) : (
          <CardMedia
            component="img"
            src={profileImage}
            loading="lazy"
            style={{
              objectFit: 'contain',
              backgroundColor: 'white',
              height: '200px',
              marginTop: '24px',
            }}
          />
        )}
        <CardContent sx={{ backgroundColor: 'white', height: '250px', overflowY: 'auto', overflowX: 'hidden' }}>
          {showHiringBadge ? (
            <Typography variant="body2" sx={{ justifyContent: 'center' }}>
              <CardMedia
                component="img"
                src={HiringIcon}
                sx={{
                  objectFit: 'contain',
                  margin: '0 auto',
                  height: '60px',
                  width: '60px',
                }}
              />
            </Typography>
          ) : null}
          <Typography variant="body1" color={UoMBlue}>
            {info[0]}
          </Typography>
          <Typography variant="h6" color={blackDark}>
            {info[1]}
          </Typography>
          <Link color={blackLight} href={info[2]} target="__blank" sx={{ display: 'flex' }}>{info[2]}</Link>
          {showContactButton ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
              <Button
                onClick={(e) => {
                  window.location.href = `mailto:${info[3]}`;
                  e.preventDefault();
                }}
                variant="contained"
                color="primary"
                sx={{
                  mr: '16px',
                  marginBottom: '10px',
                }}
              >
                Contact
              </Button>
            </Box>
          ) : null}
        </CardContent>
      </Card>
    </div>
  );
}
export default ProfileCard;
