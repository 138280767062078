import axios from 'axios';

const baseUrl = process.env.REACT_APP_UNI_RECRUIT_API;

axios.interceptors.request.use(
  async (config) => {
    if (localStorage.getItem('accessToken')) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
      // console.log('auth token', localStorage.getItem('accessToken'));
    }
    return config;
  },
  (error) => Promise.reject(error),
);

axios.interceptors.response.use(
  (res: any) => res,
  (error: any) => {
    const status = error.response ? error.response.status : null;
    if (status === 406) {
      localStorage.clear();
      window.location.replace('/');
    }
    if (status === 403) {
      window.location.replace('/forbidden');
    }
    return Promise.reject(error);
  },
);

export const signup = (jsonObj:any) => axios.post(`${baseUrl}/students/signup`, jsonObj);

export const externalSignup = (jsonObj:any) => axios.post(`${baseUrl}/students/external-signup`, jsonObj);

export const verifyRegistrationToken = (token: string) => axios.get(`${baseUrl}/students/verify-registration-token?token=${token}`);

export const confirmEmailStudent = (token: string) => axios.get(`${baseUrl}/students/confirm-email?token=${token}`);

export const resendEmailStudent = (email: string) => axios.post(`${baseUrl}/students/resend-confirmation?email=${email}`);

// export const getStudentsInfo = () => axios({
//   method: 'get',
//   url: `${baseUrl}/students`,
// });

export const editProfile = (id: string, jsonObj: any, token: string) => axios({
  method: 'put',
  url: `${baseUrl}/students/${id}`,
  data: jsonObj,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export const studentLogin = (email: string, password: string) => axios({
  method: 'post',
  url: `${baseUrl}/students/login`,
  data: {
    email,
    password,
  },
});

export const verifyStudentStatus = (email: string, token: string) => axios({
  method: 'get',
  url: `${baseUrl}/students/verify`,
  headers: { Authorization: `Bearer ${token}` },
  params: {
    email,
  },
});

export const getProfile = (id:string) => axios.get(`${baseUrl}/students/${id}`);

export const setPublish = (id: string) => axios({
  method: 'put',
  url: `${baseUrl}/students/publish/${id}`,
});

export const forgottenStudent = (email: string) => axios({
  method: 'post',
  url: `${baseUrl}/students/forget-password-email?email=${email}`,
});

export const resetPasswordStudent = (email:string, token: string, jsonObj: any) => axios({
  method: 'put',
  url: `${baseUrl}/students/password/${email}/${token}`,
  data: jsonObj,
});

export const getStudentsLike = (id: string) => axios({
  method: 'get',
  url: `${baseUrl}/students/all/${id}`,
});

export const getResume = (id: string) => axios({
  method: 'get',
  url: `${baseUrl}/students/${id}/resume`,
  responseType: 'arraybuffer',
});

export const deleteResume = (id: string) => axios({
  method: 'delete',
  url: `${baseUrl}/students/${id}/resume`,
});

export const postResume = (id: string, jsonObj: any) => axios({
  method: 'post',
  url: `${baseUrl}/students/${id}/resume`,
  data: jsonObj,
});

export const putResume = (id: string, jsonObj: any) => axios({
  method: 'put',
  url: `${baseUrl}/students/${id}/resume`,
  data: jsonObj,
});

export const putUserEmail = (id: string, email: string) => axios({
  method: 'put',
  url: `${baseUrl}/students/${id}/update-email?email=${email}`,
});

export const putUserPassword = (id: string, jsonObj: any) => axios({
  method: 'put',
  url: `${baseUrl}/students/${id}/password`,
  data: jsonObj,
});

export const deleteProfile = (id:string, jsonObj: any) => axios({
  method: 'delete',
  url: `${baseUrl}/students/${id}`,
  data: jsonObj,
});
